import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { BiChevronDown } from "react-icons/bi";

const ServiceSearchResults = () => {
  const location = useLocation();
  const [services, setServices] = useState([]);
  const [sortOption, setSortOption] = useState("rating"); // Default sort by rating
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const serviceName = queryParams.get("query");
  const ws = useRef(null);

  useEffect(() => {
    ws.current = new WebSocket("wss://api.groomsy.in");

    ws.current.onopen = () => {
      if (serviceName) {
        ws.current.send(serviceName); // Send the search keyword via WebSocket
      }
    };

    ws.current.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.services && data.services.length > 0) {
        const sortedServices = data.services.sort((a, b) => {
          if (sortOption === "rating") {
            return b.rating - a.rating;
          } else if (sortOption === "price") {
            return a.price - b.price;
          }
          return 0;
        });

        setServices(sortedServices);
      } else {
        setServices([]);
      }
    };

    ws.current.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    ws.current.onclose = () => {
      console.error("WebSocket closed");
    };

    return () => {
      ws.current.close();
    };
  }, [serviceName, sortOption]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleSortChange = (option) => {
    setSortOption(option);
    setDropdownOpen(false);
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4 relative">
        <h1 className="text-2xl font-bold">Service Search Results</h1>
        <div className="relative">
          <button
            onClick={toggleDropdown}
            className="flex items-center bg-gray-200 p-2 rounded-lg hover:bg-gray-300"
          >
            <span>Sort by: {sortOption.charAt(0).toUpperCase() + sortOption.slice(1)}</span>
            <BiChevronDown className="ml-2" />
          </button>
          {dropdownOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-10">
              <ul>
                <li
                  onClick={() => handleSortChange("rating")}
                  className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                >
                  Rating
                </li>
                <li
                  onClick={() => handleSortChange("price")}
                  className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                >
                  Price
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <p className="mb-4">Showing results for: {serviceName}</p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {services.length > 0 ? (
          services.map((service) => (
            <div
              key={service.serviceId}
              className={`bg-white p-4 rounded-lg shadow-md flex items-center border ${
                service.serviceCategory === "Men"
                  ? "border-blue-600"
                  : service.serviceCategory === "Women"
                  ? "border-pink-500"
                  : "border-gray-300"
              }`}
            >
              <img
                src={service.logo || "https://via.placeholder.com/50"}
                alt={`${service.serviceName} Logo`}
                className="w-16 h-16 rounded-full mr-4"
              />
              <div>
                <h2 className="text-lg font-bold">{service.serviceName}</h2>
                <p className="text-sm text-gray-600">{service.shopName}</p>
                <p
                  className={`text-sm font-bold px-2 py-1 rounded-lg mt-1 inline-block ${
                    service.serviceCategory === "Men"
                      ? "text-blue-600 border-blue-600 border"
                      : service.serviceCategory === "Women"
                      ? "text-pink-500 border-pink-500 border"
                      : "bg-gradient-to-r from-blue-500 to-pink-500 text-transparent bg-clip-text border border-transparent"
                  }`}
                >
                  {service.serviceCategory || "Unisex"}
                </p>
                <p className="text-sm text-gray-600">{service.location}</p>
                <p className="text-sm text-gray-600">Rating: {service.rating} ★</p>
                <p className="text-lg font-bold mt-2">₹{service.price}</p>
              </div>
            </div>
          ))
        ) : (
          <p>No services found for "{serviceName}".</p>
        )}
      </div>
    </div>
  );
};

export default ServiceSearchResults;
