import React, { useState, useEffect, useCallback } from "react";
import { FaStar, FaThumbsUp, FaThumbsDown } from "react-icons/fa";

const CustomerReviews = ({ reviewsData = [] }) => {
  const [reviews, setReviews] = useState([]);
  const [visibleReviews, setVisibleReviews] = useState(3);
  const reviewsPerPage = 3;

  useEffect(() => {
    if (Array.isArray(reviewsData)) {
      setReviews(reviewsData);
    } else {
      setReviews([]);
    }
  }, [reviewsData]);

  const loadMoreReviews = () => {
    setVisibleReviews((prevVisibleReviews) => prevVisibleReviews + reviewsPerPage);
  };

  const renderMedia = useCallback((mediaUrl, index) => {
    return (
      <div key={index} className="relative">
        {mediaUrl.endsWith(".mp4") ? (
          <video
            controls
            src={mediaUrl}
            className="w-full h-32 rounded-lg object-cover"
            preload="metadata"
          />
        ) : (
          <img
            src={mediaUrl}
            alt={`media-${index}`}
            className="w-full h-32 rounded-lg object-cover"
            loading="lazy"
            onError={(e) => (e.target.src = "/path-to-placeholder-image.jpg")} 
          />
        )}
      </div>
    );
  }, []);

  const handleUpvote = (reviewId) => {
    // Add functionality to handle upvoting here
  };

  const handleDownvote = (reviewId) => {
    // Add functionality to handle downvoting here
  };

  const reportReview = (reviewId) => {
    // Add functionality to report the review
  };

  return (
    <div className="max-w-4xl mx-auto p-0">
      <h3 className="text-xl font-semibold text-center mb-6">Customer Reviews</h3>
      {reviews.length > 0 ? (
        <div className="space-y-6">
          {reviews.slice(0, visibleReviews).map((review) => (
            <div key={review.id} className="p-4 border rounded-lg shadow-sm bg-white">
              <div className="flex items-start justify-between">
                <div className="flex items-start space-x-4">
                  <img
                    src={review.avatar}
                    alt={`${review.name}'s avatar`}
                    className="w-12 h-12 rounded-full"
                  />
                  <div>
                    <h4 className="font-semibold text-lg">{review.name}</h4>
                    <div className="flex items-center space-x-2">
                      <div className="flex space-x-1">
                        {[...Array(5)].map((_, i) => (
                          <FaStar
                            key={i}
                            color={i < review.serviceRating ? "#FFB800" : "#e4e5e9"}
                            className="h-4 w-4"
                          />
                        ))}
                      </div>
                      <span className="text-sm text-gray-500">Service Rating</span>
                    </div>
                    <div className="flex items-center space-x-2 mt-1">
                      <div className="flex space-x-1">
                        {[...Array(5)].map((_, i) => (
                          <FaStar
                            key={i}
                            color={i < review.shopRating ? "#FFB800" : "#e4e5e9"}
                            className="h-4 w-4"
                          />
                        ))}
                      </div>
                      <span className="text-sm text-gray-500">Shop Rating</span>
                    </div>
                    <p className="mt-2 text-gray-700">{review.serviceReview}</p>
                    {review.shopReview && (
                      <p className="mt-1 text-gray-500 italic">"{review.shopReview}"</p>
                    )}
                    {review.media && review.media.length > 0 && (
                      <div className="mt-3 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
                        {review.media.slice(0, 4).map(renderMedia)}
                      </div>
                    )}
                    <div className="mt-4 flex items-center space-x-4 text-sm text-gray-500">
                      <span>{review.timeAgo}</span>
                      <div className="flex space-x-2">
                        <span
                          className="flex items-center space-x-1 cursor-pointer"
                          onClick={() => handleUpvote(review.id)}
                        >
                          <FaThumbsUp className="h-4 w-4" />
                          <span>{review.upvotes}</span>
                        </span>
                        <span
                          className="flex items-center space-x-1 cursor-pointer"
                          onClick={() => handleDownvote(review.id)}
                        >
                          <FaThumbsDown className="h-4 w-4" />
                          <span>{review.downvotes}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  className="text-sm text-gray-500 hover:text-gray-700"
                  onClick={() => reportReview(review.id)}
                >
                  Report
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-center text-gray-500">No reviews available.</p>
      )}

      {visibleReviews < reviews.length && (
        <button
          onClick={loadMoreReviews}
          className="mt-6 w-full py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
        >
          Load More
        </button>
      )}
    </div>
  );
};

export default CustomerReviews;
