import React from "react";

const HeaderView = ({ navbarOpen, setNavbarOpen, isHome }) => {
  return (
    <div
      className="top-0 left-0  w-6 h-6 flex items-center justify-between z-20 cursor-pointer"
      onClick={() => setNavbarOpen(!navbarOpen)}
    >
      {/* Hamburger Icon */}
      <div className="lg:hidden items-center justify-center block  rounded-md focus:outline-none">
        <div className="relative w-6 flex flex-col justify-between items-center">
          <span
            className={`absolute block h-0.5 w-full bg-gray-800 transform transition duration-300 ease-in-out ${
              navbarOpen ? "rotate-45 translate-y-2" : "-translate-y-1.5"
            } ${isHome ? "bg-black" : "bg-black"}`}
          ></span>
          <span
            className={`absolute block h-0.5 w-full bg-gray-800 transform transition duration-300 ease-in-out ${
              navbarOpen ? "opacity-0" : "opacity-100"
            } ${isHome ? "bg-black" : "bg-black"}`}
          ></span>
          <span
            className={`absolute block h-0.5 w-full bg-gray-800 transform transition duration-300 ease-in-out ${
              navbarOpen ? "-rotate-45 -translate-y-2" : "translate-y-1.5"
            } ${isHome ? "bg-black" : "bg-black"}`}
          ></span>
        </div>
      </div>
    </div>
  );
};

export default HeaderView;
