import React from "react";

export const NextArrow = ({ onClick }) => {
  return (
    <div
      className="absolute right-0 top-4 transform -translate-y-1/2 cursor-pointer"
      onClick={onClick}
    >
      <svg
        width="30"
        height="30"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.75"
          y="0.75"
          width="34.5"
          height="34.5"
          rx="7.25"
          fill="#FAFAFA"
          className="fill-gray-50"
        />
        <rect
          x="0.75"
          y="0.75"
          width="34.5"
          height="34.5"
          rx="7.25"
          className="stroke-gray-300"
          strokeWidth="1.5"
        />
        <path
          d="M15.9399 23.28L20.2866 18.9333C20.7999 18.42 20.7999 17.58 20.2866 17.0666L15.9399 12.72"
          className="stroke-gray-800"
          strokeWidth="1.8"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
