import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import './Signup.scss';

const Signup = (props) => {

    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(props.show)
    }, [props.show])

    const onHideBtnClicked = () => {
        setShow(false);
    }


    return (
        <div>
            <Modal
                size="md"
                show={show || props.show}
                onHide={onHideBtnClicked}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Signup
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className='mt-3'>
                            <input type='text' placeholder='Full Name' className='form-control shadow-none' />
                        </div>
                        <div className='mt-3'>
                            <input type='number' placeholder='Age' className='form-control shadow-none' />
                        </div>
                        <div className='mt-3'>
                            <select className='form-control shadow-none'>
                                <option>Gender</option>
                                <option>Male</option>
                                <option>Female</option>
                            </select>
                        </div>
                        <div className='mt-3'>
                            <input type='number' placeholder='Mobile Number' className='form-control shadow-none' />
                        </div>
                        <div className='mt-3'>
                            <input type='text' placeholder='Password' className='form-control shadow-none' />
                        </div>
                        <div className='mt-3'>
                            <button className='btn btn-primary'>Signup</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )

}

export default Signup;