import React from 'react';

const RatingSummary = ({ ratings }) => {
  // Calculate total number of reviews
  const totalReviews = ratings.length
    ? ratings.reduce((sum, rating) => sum + rating.count, 0)
    : 0;

  // Calculate average rating
  const averageRating =
    totalReviews > 0
      ? ratings.reduce((sum, rating) => sum + rating.rating * rating.count, 0) /
        totalReviews
      : 0;

  return (
    <div className="p-4 bg-white shadow-md mt-6">
      <h2 className="text-lg font-bold mb-4">Ratings Summary</h2>
      <div className="flex items-center">
        <div className="flex-1">
          {totalReviews > 0 ? (
            ratings.map((rating) => (
              <div key={rating.rating} className="flex items-center mb-2">
                <span className="text-sm w-4">{rating.rating}</span>
                <div className="flex-1 h-2 bg-gray-200 ml-2 rounded-full">
                  <div
                    className="h-full bg-yellow-500 rounded-full"
                    style={{
                      width: `${(rating.count / totalReviews) * 100}%`,
                    }}
                  ></div>
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-500">No ratings available.</p>
          )}
        </div>
        <div className="ml-6 flex flex-col items-center">
          {totalReviews > 0 ? (
            <>
              <div className="text-4xl font-extrabold text-gray-800">
                {averageRating.toFixed(1)}
              </div>
              <div className="flex items-center">
                <span className="text-yellow-500 text-xl">
                  {'★'.repeat(Math.round(averageRating))}
                </span>
                <span className="text-gray-300 text-xl">
                  {'☆'.repeat(5 - Math.round(averageRating))}
                </span>
              </div>
              <div className="text-sm text-gray-500">
                ({totalReviews} Reviews)
              </div>
            </>
          ) : (
            <div className="text-center text-gray-500">
              No reviews yet.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RatingSummary;
