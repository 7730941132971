import React from "react";

const About = () => {
  return (
    <div className="container mx-auto py-6 px-4">
      <h1 className="text-3xl font-bold mb-4">About Us</h1>
      <p className="text-gray-700 leading-relaxed mb-4">
        Welcome to Groomsy! We believe that everyone deserves easy access to quality grooming services that fit their lifestyle and personal preferences. We are committed to creating a seamless experience where end consumers can discover, book, and enjoy a wide range of grooming services—whether at their favorite salon or in the comfort of their own home.
      </p>
      <p className="text-gray-700 leading-relaxed mb-4">
        Groomsy is more than just a booking platform; we are a partner in your grooming journey. Our advanced technology connects you with top-rated salons, independent stylists, and skilled grooming professionals, bringing expertise and convenience directly to you. From personalized service suggestions powered by AI to real-time appointment scheduling and transparent pricing, Groomsy empowers you to make informed choices with ease.
      </p>
      <h2 className="text-2xl font-semibold mb-2">Our Values</h2>
      <ul className="list-disc pl-6 text-gray-700 leading-relaxed mb-4">
        <li className="mb-2"><strong>Convenience:</strong> We bring you quality grooming services at your fingertips, from finding the right stylist to doorstep services tailored to your needs.</li>
        <li className="mb-2"><strong>Trust:</strong> We carefully vet our salon partners and professionals to ensure that you receive safe, hygienic, and high-quality services.</li>
        <li className="mb-2"><strong>Personalization:</strong> By understanding your unique preferences, we deliver recommendations that suit your style, personality, and grooming goals.</li>
        <li className="mb-2"><strong>Transparency:</strong> With clear pricing, honest reviews, and service details, Groomsy helps you make confident choices without any hidden surprises.</li>
      </ul>
      <p className="text-gray-700 leading-relaxed">
        Whether it’s a regular haircut or a special occasion, Groomsy makes it easier than ever to look and feel your best. Join us and experience grooming tailored to your needs.
      </p>
    </div>
  );
};

export default About;
