import React, { useEffect, useState, useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ShopHorizontalCard from "../../components/shop-horizontal-card/shop-horizontal-card";
import "./Home.scss";
import { useNavigate } from "react-router-dom";
import HomeService from "../../services/HomeService";
import HeroSearchBar from "../../components/Search/HeroSearchBar";
import { PrevArrow } from "../../components/arrows/PrevArrow";
import { NextArrow } from "../../components/arrows/NextArrow";
import HorizontalScrollProgressBar from "../../components/progress-bar/HorizontalScrollProgressBar";

import { Helmet, HelmetProvider } from "react-helmet-async";
import { FaArrowRight, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Header from "../../components/Header/Header";

const responsive = {
  superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 5 },
  desktop: { breakpoint: { max: 3000, min: 1594 }, items: 5 },
  Laptop: { breakpoint: { max: 1594, min: 1269 }, items: 4 },
  tablet: { breakpoint: { max: 1268, min: 943 }, items: 3 },
  mobile: { breakpoint: { max: 943, min: 646 }, items: 2.2 },
  mini_mobile: { breakpoint: { max: 646, min: 494 }, items: 1.5 },
  imobile: { breakpoint: { max: 493, min: 370 }, items: 1.14 },
  smobile: { breakpoint: { max: 369, min: 320 }, items: 1 },
};

const Home = () => {
  const [shopsList, setShopsList] = useState([]);
  const [scrollProgress, setScrollProgress] = useState(0);
  const navRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getShopsList();
    checkArrows();
    const nav = navRef.current;
    if (nav) nav.addEventListener("scroll", checkArrows);
    window.addEventListener("resize", checkArrows);
    return () => {
      if (nav) nav.removeEventListener("scroll", checkArrows);
      window.removeEventListener("resize", checkArrows);
    };
  }, []);

  const getShopsList = () => {
    HomeService.GetShopsList().then((res) => {
      let data = res["data"];
      setShopsList(data.shops);
    });
  };

  const navigateListBusiness = () => {
    window.open("https://partners.groomsy.in", "_blank");
  };

  const scrollLeft = () =>
    navRef.current.scrollBy({ left: -200, behavior: "smooth" });
  const scrollRight = () =>
    navRef.current.scrollBy({ left: 200, behavior: "smooth" });

  const checkArrows = () => {
    const nav = navRef.current;
    if (nav) {
      const isOverflowing = nav.scrollWidth > nav.clientWidth;
      setShowLeftArrow(isOverflowing && nav.scrollLeft > 0);
      setShowRightArrow(
        isOverflowing && nav.scrollLeft < nav.scrollWidth - nav.clientWidth
      );
    }
  };

  const handleAfterChange = (currentSlide) => {
    const totalSlides = shopsList.length;
    let progress = (100 / totalSlides) * (currentSlide + 1);
    if (currentSlide === 0) progress = 1;
    if (currentSlide + 1 === totalSlides) progress = 100;
    setScrollProgress(progress);
  };

  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>Home - Groomsy</title>
          <meta
            name="description"
            content="Discover exclusive salon and parlour services for men and women at Groomsy. Book your appointment online now!"
          />
        </Helmet>
      </HelmetProvider>

      {/* List your business */}
      <div className="flex lg:hidden justify-center items-center bg-white py-3">
        <div className="container mx-auto flex justify-center items-center">
          <span
            onClick={navigateListBusiness}
            className="text-sm md:text-base font-bold cursor-pointer flex items-center"
          >
            List your business <FaArrowRight className="ml-1 h-3 w-3" />
          </span>
        </div>
      </div>
      <hr className="w-full border-t border-gray-300 m-0 block lg:hidden" />
      <Header isHome={true} />
      <HeroSearchBar />

      <div className="relative px-2 py-8 bg-[#F2F2F2]">
        <h1 className="text-center text-sm md:text-2xl font-extrabold ">
          SALONS FOR YOU
        </h1>
        <Carousel
          swipeable={true}
          draggable={true}
          responsive={responsive}
          ssr={false}
          infinite={false}
          autoPlaySpeed={1000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={[]}
          dotListClass="custom-dot-list-style"
          itemClass="px-4 py-5 transform transition-transform duration-300 ease-in-out"
          arrows={true}
          renderButtonGroupOutside={true}
          customLeftArrow={<PrevArrow />}
          customRightArrow={<NextArrow />}
          beforeChange={(nextSlide) => handleAfterChange(nextSlide)}
        >
          {shopsList.map((data, index) => (
            <div
              key={index}
              onClick={() =>
                navigate(`/shop/${data?.shopName}?id=${data?.shopId}`)
              }
              className="flex flex-col rounded-lg items-center transition-shadow duration-300 ease-in-out cursor-pointer"
            >
              <ShopHorizontalCard shop={data} />
            </div>
          ))}
        </Carousel>
        <HorizontalScrollProgressBar progress={scrollProgress} />
        <div className="mx-auto w-full text-center mt-6">
          <span
            onClick={() => navigate(`/shops`)}
            className="text-[#2A2A2A] font-bold underline cursor-pointer flex items-center justify-center"
          >
            View All <FaArrowRight className="ml-1 h-3 w-3" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Home;
