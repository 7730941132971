import React, { useEffect, useState, useRef } from "react";
import { BiSearch, BiX } from "react-icons/bi";
import Slider1 from "../../assets/img/slider1.webp";
import Slider2 from "../../assets/img/slider2.webp";
import Slider3 from "../../assets/img/slider3.webp";
import useSearch from "../../hooks/useSearch";
import { useNavigate } from "react-router-dom";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const HeroSearchBar = () => {
  const [textIndex, setTextIndex] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [Slider1, Slider2, Slider3];
  const texts = [
    "Discover & book the right venue for your desired service",
    "Discover and book salons & grooming professionals",
  ];
  const navigate = useNavigate();
  const navRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  const checkArrows = () => {
    const nav = navRef.current;
    if (nav) {
      const isOverflowing = nav.scrollWidth > nav.clientWidth;
      setShowLeftArrow(isOverflowing && nav.scrollLeft > 0);
      setShowRightArrow(
        isOverflowing && nav.scrollLeft < nav.scrollWidth - nav.clientWidth
      );
    }
  };

  const scrollLeft = () => {
    navRef.current.scrollBy({ left: -200, behavior: "smooth" });
  };

  const scrollRight = () => {
    navRef.current.scrollBy({ left: 200, behavior: "smooth" });
  };

  useEffect(() => {
    checkArrows();

    const nav = navRef.current;
    if (nav) {
      nav.addEventListener("scroll", checkArrows);
    }

    window.addEventListener("resize", checkArrows);

    return () => {
      if (nav) {
        nav.removeEventListener("scroll", checkArrows);
      }
      window.removeEventListener("resize", checkArrows);
    };
  }, []);

  const {
    query,
    setQuery,
    handleQueryChange,
    clearSearch,
    shops,
    services,
    handleItemClick,
  } = useSearch();

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 4000);

    const textInterval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 3000);

    return () => {
      clearInterval(slideInterval);
      clearInterval(textInterval);
    };
  }, [slides.length, texts.length]);

  return (
    <div className="relative">
      {/* Hero Banner */}
      <div className="relative w-full h-[300px] sm:h-[300px] md:h-[400px] lg:h-[500px]">
        <img
          src={slides[currentSlide]}
          alt={`Slide ${currentSlide}`}
          className="w-full h-full object-cover opacity-60"
        />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-gray-900 opacity-70"></div>
      </div>

      {/* Text and Search Bar Layer */}
      <div className="absolute top-0 left-0 right-0 z-2 flex flex-col justify-center items-center h-[300px] sm:h-[300px] md:h-[400px] lg:h-[500px] px-4">
        {/* Display text on medium screens and above */}
        <h1 className="text-white text-lg sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-extrabold mb-4 text-center">
          {texts[textIndex]}
        </h1>

        {/* Unified Search Bar */}
        <div className="w-full max-w-lg sm:max-w-xl md:max-w-2xl lg:max-w-4xl mx-auto px-4 sm:px-6 md:px-8 relative">
          <div className="relative w-full flex items-center">
            <input
              type="text"
              value={query}
              onChange={handleQueryChange}
              placeholder="Search services or businesses"
              className="w-full px-3 py-2 sm:py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-lg transition duration-200 text-sm sm:text-base"
            />

            {query && (
              <button
                onClick={clearSearch}
                className="absolute right-12 text-gray-500 hover:text-red-500 transition-colors duration-150"
                aria-label="Clear Search"
              >
                <BiX className="text-lg sm:text-xl" />
              </button>
            )}

            <button
              onClick={() => handleItemClick(`/search-results?query=${query}`)}
              className="absolute right-4 bg-transparent text-gray-500 hover:text-blue-500 transition-colors duration-150"
              aria-label="Submit Search"
            >
              <BiSearch className="text-lg sm:text-xl" />
            </button>

            {/* Suggestions */}
            {query && (shops.length > 0 || services.length > 0) && (
              <div className="absolute top-full w-full bg-white mt-1 border border-gray-300 shadow-lg rounded-lg  z-50">
                <div className="p-3">
                  <div className="flex flex-col md:flex-row">
                    {services.length > 0 && (
                      <div className="md:w-1/2">
                        <h3 className="text-lg font-extrabold mb-1">
                          Services
                        </h3>
                        <ul className="space-y-1 z-50">
                          {services.map((service, index) => (
                            <li
                              key={index}
                              onClick={() =>
                                handleItemClick(
                                  `/service?name=${service.serviceName}`
                                )
                              }
                              className="cursor-pointer p-1 hover:bg-gray-100"
                            >
                              {service.serviceName}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    {shops.length > 0 && (
                      <div className="md:w-1/2 md:pl-4 mt-2 md:mt-0">
                        <h3 className="text-lg font-extrabold mb-1">Shops</h3>
                        <ul className="space-y-1 z-50">
                          {shops.map((shop, index) => (
                            <li
                              key={index}
                              onClick={() =>
                                handleItemClick(
                                  `/shop/${shop.shopName}?id=${shop.shopId}`
                                )
                              }
                              className="cursor-pointer p-1 hover:bg-gray-100"
                            >
                              {shop.shopName}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* No Results Message */}
            {query && shops.length === 0 && services.length === 0 && (
              <div className="absolute top-full w-full bg-white mt-1 border border-gray-300 shadow-lg rounded-lg z-50 p-3 text-gray-500">
                No services or shops found.
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Service List Navigation */}
      <div className="absolute bottom-5 left-0 right-0 z-3 px-4 lg:px-10 flex items-center justify-between">
        {/* Left Arrow */}
        {showLeftArrow && (
          <button
            onClick={scrollLeft}
            className="text-white p-2 bg-gray-800 rounded-full focus:outline-none"
            aria-label="Scroll Left"
          >
            <FaChevronLeft />
          </button>
        )}

        <nav
          ref={navRef}
          className="flex flex-1 mx-4 bg-transparent overflow-hidden no-scrollbar justify-start lg:justify-center items-center"
        >
          <ul className="flex gap-8 sm:gap-10 lg:gap-14 text-white text-sm sm:text-base lg:text-lg mb-0">
            {[
              "HairCut",
              "Hair Coloring",
              "Facial",
              "Massage",
              "Keratin",
              "Pedicure",
              "Hair Spa",
              "Manicure",
              "Straightening",
              "Wax",
            ].map((service) => (
              <li
                key={service}
                className="hover:text-gray-300 cursor-pointer whitespace-nowrap"
                onClick={() => {
                  navigate(`/service?name=${encodeURIComponent(service)}`);
                }}
              >
                {service}
              </li>
            ))}
          </ul>
        </nav>

        {/* Right Arrow */}
        {showRightArrow && (
          <button
            onClick={scrollRight}
            className="text-white p-2 bg-gray-800 rounded-full focus:outline-none"
            aria-label="Scroll Right"
          >
            <FaChevronRight />
          </button>
        )}
      </div>
    </div>
  );
};

export default HeroSearchBar;
