import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const SearchResultsPage = () => {
  const location = useLocation();
  const [results, setResults] = useState([]); 
  const [sortOption, setSortOption] = useState('price'); 
  const [loading, setLoading] = useState(true);

  
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get('query');
  const searchLocation = searchParams.get('location');
  const time = searchParams.get('time');

  
  useEffect(() => {
    const fetchSearchResults = async () => {
      setLoading(true);

      try {
        
        const response = await fetch(
          `/api/search?query=${query}&location=${searchLocation}&time=${time}`
        );
        const data = await response.json();
        setResults(data);
      } catch (error) {
        console.error('Error fetching search results:', error);
      }

      setLoading(false);
    };

    fetchSearchResults();
  }, [query, searchLocation, time]);

  
  const handleSortChange = (e) => {
    setSortOption(e.target.value);
    const sortedResults = [...results].sort((a, b) =>
      sortOption === 'price'
        ? a.price - b.price
        : b.rating - a.rating
    );
    setResults(sortedResults);
  };

  return (
    <div className="container mx-auto py-4">
      <h1 className="text-xl font-bold mb-4">Search Results</h1>

      <div className="flex justify-between items-center mb-4">
        <p>{results.length} results found for "{query}"</p>

        <div className="flex items-center space-x-2">
          <label htmlFor="sort" className="font-medium">Sort by:</label>
          <select
            id="sort"
            value={sortOption}
            onChange={handleSortChange}
            className="border p-2 rounded-md"
          >
            <option value="price">Price</option>
            <option value="rating">Rating</option>
          </select>
        </div>
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <ul className="space-y-4">
          {results.map((result) => (
            <li key={result.id} className="border p-4 rounded-md">
              <h2 className="text-lg font-semibold">{result.name}</h2>
              <p>Price: ₹{result.price}</p>
              <p>Rating: {result.rating} ★</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchResultsPage;
