import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const useSearch = () => {
  const [query, setQuery] = useState("");
  const [shops, setShops] = useState([]);
  const [services, setServices] = useState([]);
  const ws = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const connectWebSocket = () => {
      ws.current = new WebSocket("wss://api.groomsy.in");

      ws.current.onopen = () => {
        // WebSocket connected
      };

      ws.current.onclose = () => {
        setTimeout(connectWebSocket, 3000);
      };

      ws.current.onerror = (error) => {
        console.error("WebSocket error: ", error);
      };

      ws.current.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          if (data && data.salons && data.services) {
            setShops(data.salons.slice(0, 5));
            setServices(data.services.slice(0, 5));
          }
        } catch (err) {
          console.error("Failed to parse WebSocket message: ", err);
        }
      };
    };

    connectWebSocket();

    return () => {
      if (ws.current) ws.current.close();
    };
  }, []);

  const handleQueryChange = (e) => {
    const value = e.target.value;
    setQuery(value);

    if (ws.current && ws.current.readyState === WebSocket.OPEN && value.trim()) {
      ws.current.send(value);
    } else {
      setShops([]);
      setServices([]);
    }
  };

  const clearSearch = () => {
    setQuery("");
    setShops([]);
    setServices([]);
  };

  const handleItemClick = (path) => {
    setQuery(""); // Clear the query after selection
    setShops([]); // Reset shops
    setServices([]); // Reset services
    navigate(path); // Navigate to the selected path
  };

  return {
    query,
    setQuery,
    handleQueryChange,
    clearSearch,
    shops,
    services,
    handleItemClick,
  };
};

export default useSearch;
