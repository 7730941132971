import axios from 'axios';
import './config';

const HttpService = {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    patch: axios.patch,
    delete: axios.delete
};
export default HttpService;
