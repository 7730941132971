import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./Footer.scss";
import fbLogo from "../../assets/fb.png";
import igLogo from "../../assets/ig.png";

const Footer = ({ facebookUrl, instagramUrl }) => {
  const location = useLocation();
  const currentYear = new Date().getFullYear();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <footer className="bg-[#2A2A2A] text-white p-6">
      <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 px-0">
        {/* Company Section */}
        <div>
          <h5 className="font-bold mb-3">COMPANY</h5>
          <ul className="p-0">
            <li className="mb-2">
              <Link
                to="/about"
                className="text-gray-400 text-decoration-none hover:text-white"
              >
                About
              </Link>
            </li>
            <li className="mb-2">
              <Link
                to="/terms"
                className="text-gray-400 text-decoration-none hover:text-white"
              >
                Terms & Conditions
              </Link>
            </li>
            <li className="mb-2">
              <Link
                to="/privacy"
                className="text-gray-400 text-decoration-none hover:text-white"
              >
                Privacy Policy
              </Link>
            </li>
          </ul>
        </div>

        {/* Contact Section */}
        <div>
          <h5 className="font-bold mb-3">CONTACT</h5>
          <ul className="p-0">
            <li className="mb-2">
              <Link
                to="/contactus"
                className="text-gray-400 text-decoration-none hover:text-white"
              >
                Contact Us
              </Link>
            </li>
            <li className="mb-2">
              <Link
                to="/help"
                className="text-gray-400 text-decoration-none hover:text-white"
              >
                Help
              </Link>
            </li>
            <li className="text-gray-400">+91 81110 22400</li>
            <li className="text-gray-400">info@groomsy.in</li>
          </ul>
        </div>

        {/* Services for Women Section */}
        <div>
          <h5 className="font-bold mb-3">SERVICES FOR WOMEN</h5>
          <ul className="p-0">
            <li className="mb-2">
              <Link
                to={`/service?name=${encodeURIComponent("Haircut")}`}
                className="text-gray-400 text-decoration-none hover:text-white"
              >
                Haircut
              </Link>
            </li>
            <li className="mb-2">
              <Link
                to={`/service?name/${encodeURIComponent("Hair Coluring")}`}
                className="text-gray-400 text-decoration-none hover:text-white"
              >
                Hair Colouring
              </Link>
            </li>
            <li className="mb-2">
              <Link
                to={`/service?name/${encodeURIComponent("Facial")}`}
                className="text-gray-400 text-decoration-none hover:text-white"
              >
                Facial
              </Link>
            </li>
            <li className="mb-2">
              <Link
                to={`/service?name/${encodeURIComponent("Massage")}`}
                className="text-gray-400 text-decoration-none hover:text-white"
              >
                Massage
              </Link>
            </li>
            <li className="mb-2">
              <Link
                to={`/service?name/${encodeURIComponent("Keratin Treatment")}`}
                className="text-gray-400 text-decoration-none hover:text-white"
              >
                Keratin Treatment
              </Link>
            </li>
            <li className="mb-2">
              <Link
                to={`/service?name/${encodeURIComponent("Pedicure")}`}
                className="text-gray-400 text-decoration-none hover:text-white"
              >
                Pedicure
              </Link>
            </li>
            <li className="mb-2">
              <Link
                to={`/service?name/${encodeURIComponent("Hair Spa")}`}
                className="text-gray-400 text-decoration-none hover:text-white"
              >
                Hair Spa
              </Link>
            </li>
            <li className="mb-2">
              <Link
                to={`/service?name/${encodeURIComponent("Make-Up")}`}
                className="text-gray-400 text-decoration-none hover:text-white"
              >
                Make-Up
              </Link>
            </li>
            <li className="mb-2">
              <Link
                to={`/service?name/${encodeURIComponent("Manicure")}`}
                className="text-gray-400 text-decoration-none hover:text-white"
              >
                Manicure
              </Link>
            </li>
            <li className="mb-2">
              <Link
                to={`/service?name/${encodeURIComponent("Waxing")}`}
                className="text-gray-400 text-decoration-none hover:text-white"
              >
                Waxing
              </Link>
            </li>
          </ul>
        </div>

        {/* Services for Men Section */}
        <div>
          <h5 className="font-bold mb-3">SERVICES FOR MEN</h5>
          <ul className="p-0">
            <li className="mb-2">
              <Link
                to={`/service?name=${encodeURIComponent("Haircut")}`}
                className="text-gray-400 text-decoration-none hover:text-white"
              >
                Haircut
              </Link>
            </li>
            <li className="mb-2">
              <Link
                to={`/service?name=${encodeURIComponent("Shaving")}`}
                className="text-gray-400 text-decoration-none hover:text-white"
              >
                Shaving
              </Link>
            </li>
            <li className="mb-2">
              <Link
                to={`/service?name=${encodeURIComponent("Hair Colouring")}`}
                className="text-gray-400 text-decoration-none hover:text-white"
              >
                Hair Colouring
              </Link>
            </li>
            <li className="mb-2">
              <Link
                to={`/service?name=${encodeURIComponent("Head Massage")}`}
                className="text-gray-400 text-decoration-none hover:text-white"
              >
                Head Massage
              </Link>
            </li>
            <li className="mb-2">
              <Link
                to={`/service?name=${encodeURIComponent("Men Facial")}`}
                className="text-gray-400 text-decoration-none hover:text-white"
              >
                Facial
              </Link>
            </li>
          </ul>
        </div>
      </div>

      {/* Follow Us Section */}
      <div className="container mx-auto mt-8 text-center">
        <h5 className="font-bold mb-2">Follow Us On</h5>
        <div className="flex justify-center space-x-4 mb-4">
          <a
            href={facebookUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-400 hover:text-white"
          >
            <img
              src={fbLogo}
              alt="Facebook"
              className="w-6 h-6 object-contain"
            />
          </a>
          <a
            href={instagramUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-400 hover:text-white"
          >
            <img
              src={igLogo}
              alt="Instagram"
              className="w-6 h-6 object-contain"
            />
          </a>
        </div>
        <hr className="border-gray-600 mb-4" />
        <p className="text-gray-400 text-sm">
          © {currentYear}, PURETHOUGHTS. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
