import React from "react";

const Help = () => {
  return (
    <div className="container mx-auto py-6 px-4">
      <h1 className="text-3xl font-bold mb-4">Help & Support</h1>
      <p className="text-gray-700 leading-relaxed">
        Need help? We are here to assist you! You can reach out to us via email
        or phone, and our support team will get back to you as soon as possible...
      </p>
      {/* Add more content as needed */}
    </div>
  );
};

export default Help;
