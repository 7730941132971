import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import AuthService from "../../services/AuthService"; 

const CompleteProfile = () => {
  const { state } = useLocation(); 
  const mobileNumberFromState = state?.mobileNumber || ""; 
  
  const [profileData, setProfileData] = useState({
    age: "",
    gender: "",
    userEmail: "",
    userName: "",
    mobileNumber: mobileNumberFromState,
  });

  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  // Handle input changes
  const handleInputChange = (field, value) => {
    setProfileData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "",
    }));
  };

  // Toggle gender selection
  const toggleGender = (gender) => {
    setProfileData((prevData) => ({
      ...prevData,
      gender: gender,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      gender: "",
    }));
  };

  // Form validation
  const validateForm = () => {
    const newErrors = {};
    if (!profileData.userName) newErrors.userName = "Full Name is required";
    if (!profileData.age) newErrors.age = "Age is required";
    if (!profileData.gender) newErrors.gender = "Gender is required";
    if (
      profileData.userEmail &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(profileData.userEmail)
    ) {
      newErrors.userEmail = "Please enter a valid email";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Save profile details
  const saveUserDetails = async () => {
    if (!validateForm()) {
      toast.error("Please fill in all required fields.");
      return;
    }

    setIsSaving(true);

    try {
      const response = await AuthService.updateUserDetails({
        userName: profileData.userName,
        userEmail: profileData.userEmail,
        age: profileData.age,
        gender: profileData.gender,
        mobileNumber: profileData.mobileNumber,
      });

      if (response.status === 200) {
        toast.success("Profile completed successfully!");

        
        window.location.reload(); 
      } else {
        toast.error("Failed to update profile. Please try again.");
      }
    } catch (error) {
      toast.error("Error saving user details. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-50">
      <div className="w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl m-4 p-6 bg-white shadow-lg rounded-lg">
        <h2 className="text-center text-lg sm:text-xl md:text-2xl font-bold mb-4 md:mb-6">
          Complete Your Profile
        </h2>

        {/* Full Name */}
        <div className="mb-4">
          <label className="block text-sm md:text-base text-black font-semibold mb-2">
            Full Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            value={profileData.userName}
            onChange={(e) => handleInputChange("userName", e.target.value)}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm md:text-base"
          />
          {errors.userName && (
            <p className="text-red-500 text-xs mt-1">{errors.userName}</p>
          )}
        </div>

        {/* Mobile Number */}
        <div className="mb-4">
          <label className="block text-sm md:text-base text-black font-semibold mb-2">
            Mobile Number <span className="text-red-500">*</span>
          </label>
          <input
            type="tel"
            value={profileData.mobileNumber}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm md:text-base"
            disabled 
          />
        </div>

        {/* Email */}
        <div className="mb-4">
          <label className="block text-sm md:text-base text-black font-semibold mb-2">
            Email
          </label>
          <input
            type="email"
            value={profileData.userEmail}
            onChange={(e) => handleInputChange("userEmail", e.target.value)}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm md:text-base"
          />
          {errors.userEmail && (
            <p className="text-red-500 text-xs mt-1">{errors.userEmail}</p>
          )}
        </div>

        {/* Age */}
        <div className="mb-4">
          <label className="block text-sm md:text-base text-black font-semibold mb-2">
            Age <span className="text-red-500">*</span>
          </label>
          <input
            type="number"
            value={profileData.age}
            onChange={(e) => handleInputChange("age", e.target.value)}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm md:text-base"
          />
          {errors.age && (
            <p className="text-red-500 text-xs mt-1">{errors.age}</p>
          )}
        </div>

        {/* Gender */}
        <div className="mb-4">
          <label className="block text-sm md:text-base font-semibold mb-2">
            Gender <span className="text-red-500">*</span>
          </label>
          <div className="flex space-x-2 md:space-x-4">
            <button
              onClick={() => toggleGender("male")}
              className={`flex-1 py-2 border rounded-md text-sm md:text-base ${
                profileData.gender === "male"
                  ? "bg-blue-100 text-blue-700"
                  : "bg-gray-100 text-gray-500"
              }`}
            >
              Male
            </button>
            <button
              onClick={() => toggleGender("women")}
              className={`flex-1 py-2 border rounded-md text-sm md:text-base ${
                profileData.gender === "women"
                  ? "bg-blue-100 text-blue-700"
                  : "bg-gray-100 text-gray-500"
              }`}
            >
              Female
            </button>
          </div>
          {errors.gender && (
            <p className="text-red-500 text-xs mt-1">{errors.gender}</p>
          )}
        </div>

        {/* Buttons */}
        <div className="flex justify-between mt-4 md:mt-6">
          <button
            onClick={() => navigate("/")}
            className="py-2 px-4 bg-gray-300 rounded-md hover:bg-gray-400 text-sm md:text-base"
          >
            Cancel
          </button>
          <button
            onClick={saveUserDetails}
            className="py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 text-sm md:text-base"
            disabled={isSaving}
          >
            {isSaving ? "Saving..." : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CompleteProfile;
