import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { FaStar } from "react-icons/fa";
import { IoCloseCircle } from "react-icons/io5";

const ReviewModal = ({ show, onHide, shopName, shopLocation, logo }) => {
  const [serviceRating, setServiceRating] = useState(0);
  const [shopRating, setShopRating] = useState(0);
  const [serviceReview, setServiceReview] = useState("");
  const [shopReview, setShopReview] = useState("");
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState({});
  const [previewFile, setPreviewFile] = useState(null);

  const handleRating = (rating, type) => {
    if (type === "service") {
      setServiceRating(rating);
    } else {
      setShopRating(rating);
    }
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (files.length + selectedFiles.length > 5) {
      setErrors({ ...errors, fileLimit: "You can only upload up to 5 files." });
    } else {
      setFiles([...files, ...selectedFiles]);
      setErrors({ ...errors, fileLimit: null });
    }
    e.target.value = ""; // Reset input field to allow re-upload
  };

  const handleRemoveFile = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};
    if (!serviceRating) newErrors.serviceRating = "Service rating is required";
    if (!shopRating) newErrors.shopRating = "Shop rating is required";
    if (!serviceReview) newErrors.serviceReview = "Service review is required";
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    setServiceRating(0);
    setShopRating(0);
    setServiceReview("");
    setShopReview("");
    setFiles([]);
    setErrors({});
    onHide(); // Close the modal after submission
  };

  const handlePreview = (file) => {
    setPreviewFile(file);
  };

  const handleClosePreview = () => {
    setPreviewFile(null);
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header className="flex justify-between items-center px-3 py-2">
        <h5 className="text-lg font-semibold pt-3">{shopName}</h5>
        <button
          type="button"
          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
          onClick={onHide}
        >
          <IoClose className="h-8 w-8 p-1 bg-gray-200 rounded-full text-black" />
        </button>
      </Modal.Header>
      <Modal.Body className="px-3 py-2 md:px-6 lg:px-8">
        {/* Shop logo, name, and location */}
        <div className="text-center">
          {logo ? (
            <img src={logo} alt={`${shopName} logo`} className="mx-auto mb-2 w-20 h-20" />
          ) : (
            <div className="w-20 h-20 bg-gray-200 mx-auto mb-2"></div> // Placeholder if logo is missing
          )}
          <h4 className="font-bold">{shopName}</h4>
          <p className="text-gray-500">{shopLocation}</p>
        </div>
        
        <hr className="my-4" />
        
        {/* Review form */}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Your Service Review <span className="text-red-500">*</span>
            </label>
            <div className="flex space-x-1">
              {[1, 2, 3, 4, 5].map((value) => (
                <FaStar
                  key={value}
                  size={30}
                  color={serviceRating >= value ? "#FFB800" : "#e4e5e9"}
                  onClick={() => handleRating(value, "service")}
                  className="cursor-pointer"
                />
              ))}
            </div>
            {errors.serviceRating && <p className="text-red-500 text-sm">{errors.serviceRating}</p>}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Your Shop Review <span className="text-red-500">*</span>
            </label>
            <div className="flex space-x-1">
              {[1, 2, 3, 4, 5].map((value) => (
                <FaStar
                  key={value}
                  size={30}
                  color={shopRating >= value ? "#FFB800" : "#e4e5e9"}
                  onClick={() => handleRating(value, "shop")}
                  className="cursor-pointer"
                />
              ))}
            </div>
            {errors.shopRating && <p className="text-red-500 text-sm">{errors.shopRating}</p>}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Leave a Review for the Service <span className="text-red-500">*</span>
            </label>
            <textarea
              className="w-full border border-gray-300 rounded-lg p-2"
              maxLength="2500"
              value={serviceReview}
              onChange={(e) => setServiceReview(e.target.value)}
            ></textarea>
            {errors.serviceReview && <p className="text-red-500 text-sm">{errors.serviceReview}</p>}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Leave a Review for the Shop (Optional)
            </label>
            <textarea
              className="w-full border border-gray-300 rounded-lg p-2"
              maxLength="2500"
              value={shopReview}
              onChange={(e) => setShopReview(e.target.value)}
            ></textarea>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Add up to 5 photos or videos
            </label>
            <div className="border border-dashed border-gray-300 rounded-lg p-4 flex flex-col items-center justify-center">
              <input
                type="file"
                multiple
                accept="image/*,video/*"
                className="hidden"
                id="file-upload"
                onChange={handleFileChange}
              />
              <label
                htmlFor="file-upload"
                className="cursor-pointer bg-gray-400 text-white py-2 px-4 rounded-lg"
              >
                Drag & Drop or Click to Upload
              </label>
            </div>
            {errors.fileLimit && <p className="text-red-500 text-sm">{errors.fileLimit}</p>}
            <p className="text-sm text-gray-500 mt-2">Maximum of 5 files allowed</p>
            <div className="flex flex-wrap gap-2 mt-4">
              {files.map((file, index) => (
                <div key={index} className="relative w-20 h-20">
                  {file.type.startsWith("image/") ? (
                    <img
                      src={URL.createObjectURL(file)}
                      alt="thumbnail"
                      className="w-full h-full object-cover rounded-lg cursor-pointer"
                      onClick={() => handlePreview(file)}
                    />
                  ) : (
                    <video
                      src={URL.createObjectURL(file)}
                      controls
                      className="w-full h-full object-cover rounded-lg cursor-pointer"
                      onClick={() => handlePreview(file)}
                    />
                  )}
                  <IoCloseCircle
                    className="absolute top-0 right-0 text-red-500 cursor-pointer"
                    size={20}
                    onClick={() => handleRemoveFile(index)}
                  />
                </div>
              ))}
            </div>
          </div>
          <Button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 rounded-lg"
          >
            Post Review
          </Button>
        </form>
      </Modal.Body>

      {/* Preview Modal */}
      {previewFile && (
        <Modal show={true} onHide={handleClosePreview} centered size="lg">
          <Modal.Header>
            <button
              type="button"
              className="ml-auto text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center"
              onClick={handleClosePreview}
            >
              <IoClose className="h-8 w-8 p-1 bg-gray-200 rounded-full text-black" />
            </button>
          </Modal.Header>
          <Modal.Body className="flex justify-center">
            {previewFile.type.startsWith("image/") ? (
              <img
                src={URL.createObjectURL(previewFile)}
                alt="preview"
                className="w-full h-auto object-contain"
              />
            ) : (
              <video
                src={URL.createObjectURL(previewFile)}
                controls
                className="w-full h-auto object-contain"
              />
            )}
          </Modal.Body>
        </Modal>
      )}
    </Modal>
  );
};

export default ReviewModal;
