import React from "react";
import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";

const ContactUs = () => {
  return (
    <div className="container mx-auto py-6 px-4">
      <h1 className="text-3xl font-bold mb-4">Contact Us</h1>
      <p className="text-gray-700 leading-relaxed mb-4">
        If you have any questions or concerns about this Privacy Policy or how we handle your personal information, please contact us at:
      </p>
      <div className="text-gray-700 leading-relaxed">
        <p className="mb-2 flex items-center">
          <FaEnvelope className="text-blue-600 mr-2" />
          <strong>Email:</strong>
          <a href="mailto:info@groomsy.in" className="text-blue-600 hover:underline ml-2">
            info@groomsy.in
          </a>
        </p>
        <p className="mb-2 flex items-center">
          <FaMapMarkerAlt className="text-blue-600 mr-2" />
          <strong>Address:</strong>
          <span className="ml-2">45, Ramraj Nagar, Goldwins, Kalapatti East, Coimbatore - 641014</span>
        </p>
        <p className="mb-2 flex items-center">
          <FaPhoneAlt className="text-blue-600 mr-2" />
          <strong>Phone:</strong>
          <a href="tel:+918111022400" className="text-blue-600 hover:underline ml-2">
            +918111022400
          </a>
        </p>
      </div>
    </div>
  );
};

export default ContactUs;
