import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaRegUserCircle } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import LoginModal from "../Modal/LoginModal";
import LogoImage from "../../assets/logo/logo_resized.png";
import HeaderView from "./HeaderView";
import MenuOverlay from "./MenuOverlay";
import AuthService from "../../services/AuthService";
import useSearch from "../../hooks/useSearch";

const Header = ({ isHome }) => {
  const navigate = useNavigate();

  const [modalShow, setModalShow] = useState(false);
  const [isTokenAvailable, setIsTokenAvailable] = useState(false);
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({ userName: "User" });

  const {
    query,
    setQuery,
    handleQueryChange,
    handleItemClick,
    shops,
    services,
    clearSearch,
  } = useSearch();

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsTokenAvailable(!!token);

    if (token) {
      AuthService.getUserDetails()
        .then((response) => {
          if (response.data && response.data.user) {
            setUserDetails({ userName: response.data.user.userName });
          }
        })
        .catch((err) => {
          console.error("An error occurred:", err);
        });
    }
  }, []);

  const logout = () => {
    AuthService.logout();
    setIsTokenAvailable(false);
    setUserDetails([{ name: "User" }]);
    navigate("/");
    window.location.reload();
  };

  const navigateHome = () => {
    navigate("/");
  };

  const navigateListBusiness = () => {
    window.open("https://partners.groomsy.in", "_blank");
  };

  const handleProfileClick = () => {
    navigate("/edit-profile");
  };

  return (
    <>
      <header
        className={`${!isHome ? "shadow-md bg-white" : "  bg-white shadow-md"}`}
      >
        <nav className="container mx-auto flex justify-between items-center p-4">
          {/* Logo */}
          <div
            onClick={navigateHome}
            className="flex items-center cursor-pointer"
          >
            <img src={LogoImage} alt="Logo" className="h-[34px]" />
          </div>

          {/* Search Bar for Desktop */}
          {!isHome && (
            <div className="flex-1 max-w-sm hidden md:flex mx-4 relative">
              <input
                type="text"
                placeholder="Search services or businesses..."
                value={query}
                onChange={handleQueryChange}
                className="w-full px-3 py-2 border rounded-lg focus:outline-none"
              />
              {query && (
                <button
                  onClick={clearSearch}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-red-500 transition-colors duration-150"
                  aria-label="Clear Search"
                >
                  <IoClose className="text-lg" />
                </button>
              )}
              {/* Suggestions */}
              {query && (shops.length > 0 || services.length > 0) && (
                <div className="absolute top-full left-0 w-full bg-white mt-1 border border-gray-300 shadow-lg rounded-lg z-[9999]">
                  <div className="p-3">
                    <div className="flex flex-col md:flex-row">
                      {services.length > 0 && (
                        <div className="md:w-1/2">
                          <h3 className="text-lg font-extrabold mb-1">
                            Services
                          </h3>
                          <ul className="space-y-1 p-0">
                            {services.map((service, index) => (
                              <li
                                key={index}
                                onClick={() =>
                                  handleItemClick(
                                    `/service?name=${service.serviceName}`
                                  )
                                }
                                className="cursor-pointer p-1 hover:bg-gray-100"
                              >
                                {service.serviceName}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                      {shops.length > 0 && (
                        <div className="md:w-1/2 md:pl-4 mt-2 md:mt-0">
                          <h3 className="text-lg font-extrabold mb-1">Shops</h3>
                          <ul className="space-y-1 p-0">
                            {shops.map((shop, index) => (
                              <li
                                key={index}
                                onClick={() =>
                                  handleItemClick(
                                    `/shop/${shop.shopName}?id=${shop.shopId}`
                                  )
                                }
                                className="cursor-pointer p-1 hover:bg-gray-100"
                              >
                                {shop.shopName}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Desktop Navigation Links */}
          <div
            className={`hidden lg:flex items-center space-x-4 ${
              isHome ? "text-gray-900" : "text-gray-900"
            }`}
          >
            {isHome && (
              <div className="button-3d-container">
                <button
                  onClick={navigateListBusiness}
                  className="button-3d-front text-sm font-bold px-4 py-2 bg-gray-200 text-gray-900 rounded-lg shadow-md"
                >
                  List your Business
                </button>
                <button
                  onClick={navigateListBusiness}
                  className="button-3d-back text-sm font-bold px-4 py-2 bg-gray-500 text-white rounded-lg shadow-md"
                >
                  Let’s Work Together
                </button>
              </div>
            )}

            {isTokenAvailable && (
              <Link
                to="/booking-history"
                className="text-sm text-current hover:text-gray-400"
              >
                Appointments
              </Link>
            )}

            {isTokenAvailable ? (
              <>
                <FaRegUserCircle
                  onClick={handleProfileClick}
                  className="cursor-pointer text-lg hover:text-gray-400 w-8 h-8"
                />
                <button
                  onClick={logout}
                  className="text-sm hover:text-gray-400"
                >
                  Logout
                </button>
              </>
            ) : (
              <button
                onClick={() => setModalShow(true)}
                className="text-sm font-bold px-4 py-2 bg-gray-200 text-black  rounded-lg hover:!bg-gray-500 hover:!text-white transition duration-200 shadow-md"
              >
                Login
              </button>
            )}
          </div>

          {/* Tablet and Mobile Icons and Login Button */}
          <div className="flex items-center lg:hidden space-x-4">
            {!isTokenAvailable && (
              <button
                onClick={() => setModalShow(true)}
                className="text-sm font-bold px-4 py-2 bg-gray-200 text-black  rounded-lg hover:!bg-gray-500 hover:!text-white transition duration-200 shadow-md"
              >
                Login
              </button>
            )}
            {/* Show HeaderView only if the user is logged in */}
            {isTokenAvailable && (
              <HeaderView
                navbarOpen={navbarOpen}
                setNavbarOpen={setNavbarOpen}
                isHome={isHome}
              />
            )}
          </div>
        </nav>

        {/* Search Bar for Mobile */}
        {!isHome && (
          <div className="container mx-auto px-4 pb-4  md:hidden relative z-[9999]">
            <div className="relative w-full">
              <input
                type="text"
                placeholder="Search services or businesses..."
                value={query}
                onChange={handleQueryChange}
                className="w-full px-3 pr-10 py-2 border rounded-lg focus:outline-none"
              />

              {/* Close Icon Positioned Inside Input Field */}
              {query && (
                <button
                  onClick={clearSearch}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-red-500 transition-colors duration-150"
                  aria-label="Clear Search"
                >
                  <IoClose className="text-lg" />
                </button>
              )}
            </div>
            {/* Suggestions */}
            {query && (shops.length > 0 || services.length > 0) && (
              <div className="absolute top-full left-0 w-full bg-white mt-1 border border-gray-300 shadow-lg rounded-lg z-50">
                <div className="p-3">
                  <div className="flex flex-col md:flex-row">
                    {services.length > 0 && (
                      <div className="md:w-1/2">
                        <h3 className="text-lg font-extrabold mb-1">
                          Services
                        </h3>
                        <ul className="space-y-1 p-0">
                          {services.map((service, index) => (
                            <li
                              key={index}
                              onClick={() =>
                                handleItemClick(
                                  `/service?name=${service.serviceName}`
                                )
                              }
                              className="cursor-pointer p-1 hover:bg-gray-100"
                            >
                              {service.serviceName}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    {shops.length > 0 && (
                      <div className="md:w-1/2 md:pl-4 mt-2 md:mt-0">
                        <h3 className="text-lg font-extrabold mb-1">Shops</h3>
                        <ul className="space-y-1 p-0">
                          {shops.map((shop, index) => (
                            <li
                              key={index}
                              onClick={() =>
                                handleItemClick(
                                  `/shop/${shop.shopName}?id=${shop.shopId}`
                                )
                              }
                              className="cursor-pointer p-1 hover:bg-gray-100"
                            >
                              {shop.shopName}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {/* Mobile Menu Overlay */}
        <MenuOverlay
          navbarOpen={navbarOpen}
          setNavbarOpen={setNavbarOpen}
          isTokenAvailable={isTokenAvailable}
        />

        {/* Login Modal */}
        <LoginModal show={modalShow} onHide={() => setModalShow(false)} />
      </header>
    </>
  );
};

export default Header;
