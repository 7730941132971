import React from "react";

const Privacy = () => {
  return (
    <div className="container mx-auto py-6 px-4">
      <h1 className="text-3xl font-bold mb-4">Groomsy Privacy Policy</h1>
      <p className="text-gray-700 leading-relaxed mb-4">
        <strong>Effective Date:</strong> [Insert Date]
      </p>
      <p className="text-gray-700 leading-relaxed mb-4">
        Groomsy ("we," "our," or "us") respects your privacy and is committed to protecting the personal information you share with us. This Privacy Policy explains how we collect, use, disclose, and protect the personal information of salons, independent grooming professionals, and end users ("you" or "your") through our platform and services.
      </p>

      <h2 className="text-2xl font-semibold mb-2">1. Information We Collect</h2>
      <h3 className="text-xl font-semibold mb-2">
        1.1 For Salons and Independent Grooming Professionals:
      </h3>
      <p className="text-gray-700 leading-relaxed mb-4">
        We collect the following types of information:
      </p>
      <ul className="list-disc pl-6 text-gray-700 leading-relaxed mb-4">
        <li className="mb-2">
          <strong>Business Information:</strong> Salon name, location, services offered, business hours, pricing, and employee information.
        </li>
        <li className="mb-2">
          <strong>Account Information:</strong> Name, email, phone number, payment details, and business identification.
        </li>
        <li className="mb-2">
          <strong>Professional Credentials:</strong> License or certifications required by regulatory bodies for offering grooming services.
        </li>
        <li className="mb-2">
          <strong>Appointment Data:</strong> Booked appointments, customer preferences, and service history.
        </li>
        <li className="mb-2">
          <strong>Marketing Preferences:</strong> Opt-ins for marketing campaigns or promotional materials.
        </li>
      </ul>

      <h3 className="text-xl font-semibold mb-2">1.2 For End Users:</h3>
      <p className="text-gray-700 leading-relaxed mb-4">
        We collect the following types of information:
      </p>
      <ul className="list-disc pl-6 text-gray-700 leading-relaxed mb-4">
        <li className="mb-2">
          <strong>Personal Information:</strong> Name, email address, phone number, and profile picture.
        </li>
        <li className="mb-2">
          <strong>Appointment Data:</strong> Booking history, service preferences, and stylist/salon choices.
        </li>
        <li className="mb-2">
          <strong>Location Information:</strong> For booking nearby salons or doorstep services, we may collect your location data (with your consent).
        </li>
        <li className="mb-2">
          <strong>Payment Information:</strong> Payment method details and billing address for services booked through our platform.
        </li>
        <li className="mb-2">
          <strong>Usage Data:</strong> Interaction with the app, features used, and time spent on the platform.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">2. How We Use Your Information</h2>
      <p className="text-gray-700 leading-relaxed mb-4">
        We use the information we collect in the following ways:
      </p>
      <h3 className="text-xl font-semibold mb-2">
        2.1 For Salons and Independent Grooming Professionals:
      </h3>
      <ul className="list-disc pl-6 text-gray-700 leading-relaxed mb-4">
        <li className="mb-2">
          <strong>To Provide Services:</strong> Manage salon profiles, facilitate bookings, and communicate with your clients.
        </li>
        <li className="mb-2">
          <strong>Marketing and Promotions:</strong> Tailored marketing campaigns, special offers, and promotions targeted to potential clients.
        </li>
        <li className="mb-2">
          <strong>Analytics and Insights:</strong> Offer data-driven insights to optimize your service offerings and business operations.
        </li>
        <li className="mb-2">
          <strong>Compliance:</strong> Ensure compliance with local laws, licensing, and regulatory requirements.
        </li>
      </ul>

      <h3 className="text-xl font-semibold mb-2">2.2 For End Users:</h3>
      <ul className="list-disc pl-6 text-gray-700 leading-relaxed mb-4">
        <li className="mb-2">
          <strong>To Provide Services:</strong> Facilitate your salon or grooming service bookings and deliver personalized service suggestions.
        </li>
        <li className="mb-2">
          <strong>Personalized Experience:</strong> Use your preferences and booking history to recommend relevant salons, services, and professionals.
        </li>
        <li className="mb-2">
          <strong>Payments:</strong> Process your payments securely and provide receipts.
        </li>
        <li className="mb-2">
          <strong>Customer Support:</strong> Assist you with any inquiries or issues with bookings or services.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">3. Data Sharing and Disclosure</h2>
      <p className="text-gray-700 leading-relaxed mb-4">
        We do not sell your personal information to third parties. However, we may share your information in the following circumstances:
      </p>

      <h3 className="text-xl font-semibold mb-2">
        3.1 With Salons and Grooming Professionals:
      </h3>
      <p className="text-gray-700 leading-relaxed mb-4">
        When you book a service, your appointment details, preferences, and contact information are shared with the salon or grooming professional to fulfill the service.
      </p>

      <h3 className="text-xl font-semibold mb-2">3.2 With Service Providers:</h3>
      <p className="text-gray-700 leading-relaxed mb-4">
        We may share your information with third-party service providers who assist us in operating our platform (e.g., payment processors, email providers, and analytics services). These providers are required to protect your information and can only use it for the purposes we specify.
      </p>

      <h3 className="text-xl font-semibold mb-2">3.3 Legal Requirements:</h3>
      <p className="text-gray-700 leading-relaxed mb-4">
        We may disclose your information if required by law, to comply with legal obligations, or in response to valid legal requests from government authorities.
      </p>

      <h2 className="text-2xl font-semibold mb-2">4. Data Security</h2>
      <p className="text-gray-700 leading-relaxed mb-4">
        We implement appropriate security measures to protect your personal information from unauthorized access, alteration, or disclosure. We use encryption, secure servers, and access controls to ensure the safety of your data.
      </p>
      <p className="text-gray-700 leading-relaxed mb-4">
        However, no method of transmission over the internet or electronic storage is 100% secure, so we cannot guarantee absolute security. You are responsible for keeping your account login credentials confidential.
      </p>

      <h2 className="text-2xl font-semibold mb-2">5. Data Retention</h2>
      <p className="text-gray-700 leading-relaxed mb-4">
        We retain your personal information for as long as necessary to provide our services or as required by applicable laws. If you wish to delete your account or any of your personal data, please contact us at [Insert Contact Information]. However, we may retain certain information for legal, accounting, or compliance purposes.
      </p>

      <h2 className="text-2xl font-semibold mb-2">6. Your Privacy Rights</h2>
      <p className="text-gray-700 leading-relaxed mb-4">
        You have the right to access, correct, or delete your personal information. You can update your account details directly through the platform or contact us for assistance.
      </p>

      <h3 className="text-xl font-semibold mb-2">6.1 For End Users:</h3>
      <ul className="list-disc pl-6 text-gray-700 leading-relaxed mb-4">
        <li className="mb-2">
          <strong>Access and Update Information:</strong> You can view and update your personal information in your account settings.
        </li>
        <li className="mb-2">
          <strong>Opt-Out:</strong> You can opt out of marketing communications by following the unsubscribe link in any promotional email or updating your preferences in your account settings.
        </li>
        <li className="mb-2">
          <strong>Request Deletion:</strong> You can request the deletion of your account and personal data by contacting us.
        </li>
      </ul>

      <h3 className="text-xl font-semibold mb-2">
        6.2 For Salons and Independent Grooming Professionals:
      </h3>
      <ul className="list-disc pl-6 text-gray-700 leading-relaxed mb-4">
        <li className="mb-2">
          <strong>Access Business Data:</strong> You can view, update, or correct your business information through the platform.
        </li>
        <li className="mb-2">
          <strong>Data Portability:</strong> You can request a copy of the data you provided to us for your records.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">7. Third-Party Links</h2>
      <p className="text-gray-700 leading-relaxed mb-4">
        Our platform may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these external sites. We encourage you to review their privacy policies before providing any personal information.
      </p>

      <h2 className="text-2xl font-semibold mb-2">8. Changes to This Privacy Policy</h2>
      <p className="text-gray-700 leading-relaxed mb-4">
        We may update this Privacy Policy from time to time to reflect changes in our services or applicable laws. Any updates will be posted on this page, and we will notify you of significant changes through email or the platform.
      </p>
    </div>
  );
};

export default Privacy;
