import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ShopSearchResults = () => {
  const location = useLocation();
  const [shops, setShops] = useState([]);
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get("query");

  useEffect(() => {
    // Fetch shop search results based on the query
    fetchShops(query);
  }, [query]);

  const fetchShops = (query) => {
    // Dummy data for shop search results
    const fetchedShops = [
      {
        id: 1,
        name: "G3 Luxury Salon",
        category: "Uni-Sex",
        location: "Coimbatore",
        aboutUs:
          "G3 Luxury Salon offers top-notch services for men's grooming, including haircuts, beard shaping, and more. Our experienced stylists ensure you leave with a fresh look every time.",
        rating: 4.5,
        logo: "https://via.placeholder.com/50", // Replace with actual logo URL
      },
      {
        id: 2,
        name: "Bliss Spa",
        category: "Women",
        location: "Chennai",
        aboutUs:
          "Bliss Spa provides a serene environment for women to relax and rejuvenate. Our services include haircuts, facials, and massages, all delivered by professional staff.",
        rating: 4.0,
        logo: "https://via.placeholder.com/50", // Replace with actual logo URL
      },
      // ... more shops
    ];

    setShops(fetchedShops);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Shop Search Results</h1>
      <p className="mb-4">Showing results for: {query}</p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {shops.map((shop) => (
          <div
            key={shop.id}
            className="bg-white p-4 rounded-lg shadow-md flex flex-col"
          >
            <div className="flex items-center mb-4">
              <img
                src={shop.logo}
                alt={`${shop.name} Logo`}
                className="w-16 h-16 rounded-full mr-4"
              />
              <div>
                <h2 className="text-lg font-bold">{shop.name}</h2>
                <p className="text-sm text-gray-600">{shop.location}</p>
                <p
                  className={`text-sm font-bold px-2 py-1 rounded-lg mt-1 inline-block ${
                    shop.category === "Men"
                      ? "text-blue-600 border-blue-600 border"
                      : shop.category === "Women"
                      ? "text-pink-500 border-pink-500 border"
                      : "bg-gradient-to-r from-blue-500 to-pink-500 text-transparent bg-clip-text border border-transparent"
                  }`}
                >
                  {shop.category}
                </p>
                <p className="text-sm text-gray-600 mt-2">
                  Rating: {shop.rating} ★
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShopSearchResults;
