import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Shops.scss";
import ShopHorizontalCard from "../../components/shop-horizontal-card/shop-horizontal-card";
import HomeService from "../../services/HomeService";
import { dotSpinner } from "ldrs";

dotSpinner.register();

const Shops = () => {
  const [shopsList, setShopsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  let navigate = useNavigate();

  useEffect(() => {
    getShopsList();
  }, []);

  const getShopsList = () => {
    HomeService.GetShopsList().then((res) => {
      let data = res["data"];
      setShopsList(data.shops);
      setIsLoading(false);
    });
  };

  return (
    <div className="py-4 bg-[#F2F2F2]">
      <div className="text-center">
        <h1 className="text-base md:text-2xl font-extrabold">
          Salons and Parlours Near You
        </h1>
      </div>
      <div className="p-0 md:p-6 bg-[#F2F2F2]">
        <div className="container mx-auto">
          {isLoading ? (
            <div className="flex justify-center items-center w-full h-64">
              <l-dot-spinner
                size="40"
                speed="0.9"
                color="black"
              ></l-dot-spinner>{" "}
              {/* Loader */}
            </div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 justify-items-center">
              {shopsList.map((data, index) => (
                <div
                  onClick={() => {
                    navigate(`/shop/${data?.shopName}?id=${data?.shopId}`);
                  }}
                  className="px-1 cursor-pointer"
                  key={index}
                >
                  <ShopHorizontalCard shop={data} />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Shops;
