import React from "react";

const HorizontalScrollProgressBar = ({ progress }) => {
  return (
    <div className="w-full bg-[#EEEEEE] h-1 rounded-full mt-0">
      <div
        className="bg-black h-full rounded-full transition-all duration-300"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};

export default HorizontalScrollProgressBar;
